<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('auth.name') }}</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field
							v-model="name.first"
							:label="$t('auth.firstName')"
							:rules="rules.name.first"
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							style="max-width:400px;"
						/>
					</v-col>

					<v-col cols="12" sm="6">
						<v-text-field
							v-model="name.last"
							:label="$t('auth.lastName')"
							:rules="rules.name.last"
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							style="max-width:400px;"
						/>
					</v-col>
				</v-row>
				<v-btn color="primary" rounded @click="callUpdate()">
					{{ $t('settings.update') }}
				</v-btn>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangeAccountNameForm',
	props: {
		inputName: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			name: {
				first: '',
				last: ''
			},
			rules: {
				name: {
					first: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.firstNameMaxLength) || this.$t('rules.length', { length: this.firstNameMaxLength })
					],
					last: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.lastNameMaxLength) || this.$t('rules.length', { length: this.lastNameMaxLength })
					]
				}
			}
		}
	},
	computed: {
		firstNameMaxLength() {
			return 64
		},
		lastNameMaxLength() {
			return 128
		}
	},
	created() {
		this.name = this.inputName
		this.loading = false
	},
	methods: {
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editAccountName(this.name).then(() => {
				this.loading = false
			})
		},
		...mapActions('settings', ['editAccountName'])
	}
}
</script>
